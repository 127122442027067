/**
 * HeaderIcon component.
 */
export function HeaderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 304.1 137.6"
      width="172"
      height="62"
    >
      <g id="XMLID_163_">
        <g id="XMLID_167_">
          <path
            id="XMLID_175_"
            className="st0"
            fill="#561FFF"
            d="M258.4,76.6c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5    c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5c10.8,0,19.5,8.7,19.5,19.5S269.2,76.6,258.4,76.6z"
          />
          <g id="XMLID_169_">
            <path
              id="XMLID_174_"
              className="st0"
              fill="#561FFF"
              d="M138,30c0-2.4-0.9-4.8-2.7-6.5c-1.6-1.6-3.6-2.4-5.8-2.4c-2.4,0-4.8,0.9-6.5,2.7     c-1.6,1.6-2.4,3.6-2.4,5.8c0,2.4,0.9,4.8,2.7,6.5c0.4,0.4,0.9,0.8,1.4,1.1c0.9,0.6,1,1.8,0.1,2.5c-1.8,1.4-3,3.5-3,6v40.6     c0,4.1,3.4,7.5,7.5,7.5l0,0c4.1,0,7.5-3.4,7.5-7.5V45.6c0-2.4-1.2-4.6-3-6c-0.8-0.6-0.9-1.9,0-2.4c0.6-0.4,1.2-0.9,1.8-1.4     C137.2,34.2,138,32.2,138,30z"
            />
            <path
              id="XMLID_173_"
              className="st0"
              fill="#561FFF"
              d="M216,30c0-2.4-0.9-4.8-2.7-6.5c-1.6-1.6-3.6-2.4-5.8-2.4c-2.4,0-4.8,0.9-6.5,2.7     c-1.6,1.6-2.4,3.6-2.4,5.8c0,2.4,0.9,4.8,2.7,6.5c0.4,0.4,0.9,0.8,1.4,1.1c0.9,0.6,1,1.8,0.1,2.5c-1.8,1.4-3,3.5-3,6v40.6     c0,4.1,3.4,7.5,7.5,7.5l0,0c4.1,0,7.5-3.4,7.5-7.5V45.6c0-2.4-1.2-4.6-3-6c-0.8-0.6-0.9-1.9,0-2.4c0.6-0.4,1.2-0.9,1.8-1.4     C215.2,34.2,216,32.2,216,30z"
            />
            <path
              id="XMLID_172_"
              className="st0"
              fill="#561FFF"
              d="M155.3,10.1L155.3,10.1c-4.1,0-7.5,3.4-7.5,7.5v60.6c0,4.1,3.4,7.5,7.5,7.5l0,0     c4.1,0,7.5-3.4,7.5-7.5V17.6C162.8,13.5,159.5,10.1,155.3,10.1z"
            />
            <path
              id="XMLID_171_"
              className="st0"
              fill="#561FFF"
              d="M181.3,28.1L181.3,28.1c-4.1,0-7.5,3.4-7.5,7.5v60.6c0,4.1,3.4,7.5,7.5,7.5l0,0     c4.1,0,7.5-3.4,7.5-7.5V35.6C188.8,31.5,185.5,28.1,181.3,28.1z"
            />
            <path
              id="XMLID_170_"
              className="st0"
              fill="#561FFF"
              d="M233.3,43.5L233.3,43.5c-4.1,0-7.5,3.4-7.5,7.5v73.6c0,4.1,3.4,7.5,7.5,7.5l0,0     c4.1,0,7.5-3.4,7.5-7.5V51C240.8,46.8,237.5,43.5,233.3,43.5z"
            />
          </g>
          <path
            id="XMLID_168_"
            className="st0"
            fill="#561FFF"
            d="M258.4,102.7c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5c16.9,0,30.6-13.7,30.6-30.6    c0-16.9-13.7-30.6-30.6-30.6c-7.4,0-14.6,2.7-20.2,7.6c-3.1,2.7-7.9,2.4-10.6-0.7c-2.7-3.1-2.4-7.9,0.7-10.6    c8.3-7.3,19-11.3,30.1-11.3c25.2,0,45.6,20.5,45.6,45.6C304.1,82.3,283.6,102.7,258.4,102.7z"
          />
        </g>
        <path
          id="XMLID_165_"
          className="st0"
          fill="#561FFF"
          d="M48.4,27.3c-2.4-1.6-5.3-2.8-8.6-3.5c-3.3-0.7-6.7-1-10.4-1H9.7c-3.6,0-6.6,3-6.6,6.6v0   c0,3.6,3,6.6,6.6,6.6h17c1.5,0,3,0.1,4.6,0.2c1.6,0.2,3.1,0.5,4.4,1.1c1.3,0.6,2.4,1.5,3.2,2.5c0.8,1.1,1.2,2.6,1.2,4.4   c0,1.7-0.3,3-1,4.1c-0.6,1.1-1.5,2-2.5,2.6c-1,0.6-2.2,1.1-3.5,1.3c-1.3,0.3-2.6,0.4-4,0.4H9.7c-3.6,0-6.6,3-6.6,6.6v27.6   c0,3.6,3,6.6,6.6,6.6h2.4c3.6,0,6.6-3,6.6-6.6V72.7c0-3.6,3-6.6,6.6-6.6h4.8c3.7,0,7.2-0.4,10.4-1.1c3.2-0.7,6-1.9,8.3-3.5   c2.3-1.7,4.2-3.9,5.5-6.7c1.3-2.8,2-6.3,2-10.4c0-4.2-0.7-7.7-2.1-10.5C52.7,31.2,50.8,29,48.4,27.3z"
        />
        <path
          id="XMLID_164_"
          className="st0"
          fill="#561FFF"
          d="M107.7,50.5c-1.3-2.1-3.1-3.7-5.5-4.8c-2.3-1.2-5.4-1.8-9.2-1.8c-1.8,0-3.5,0.2-5,0.7   c-1.5,0.5-2.9,1.1-4,1.8c-1.1,0.7-2.1,1.6-2.9,2.5c-0.8,0.9-1.4,1.9-1.7,2.8h-0.2V25.6c0-4.1-3.4-7.5-7.5-7.5h0   c-4.1,0-7.5,3.4-7.5,7.5v60.6c0,4.1,3.4,7.5,7.5,7.5h0c4.1,0,7.5-3.4,7.5-7.5V69.5c0-1.6,0.1-3.1,0.3-4.6c0.2-1.5,0.6-2.8,1.2-4   c0.6-1.2,1.5-2.1,2.8-2.8c1.2-0.7,2.8-1,4.7-1c1.9,0,3.4,0.4,4.5,1.2c1,0.8,1.8,1.8,2.3,3.1c0.5,1.3,0.8,2.6,0.9,4.1   c0.1,1.5,0.1,3,0.1,4.4v16.3c0,4.1,3.4,7.5,7.5,7.5h0c4.1,0,7.5-3.4,7.5-7.5V66.9c0-3.3-0.2-6.3-0.7-9.1   C109.9,55,109,52.6,107.7,50.5z"
        />
      </g>
    </svg>
  );
}
