import { AppShell, Box, Burger, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import LanguageMenu from 'components/LanguageMenu';
import theme from 'theme';
import { HeaderIcon } from 'components/icons/HeaderIcon';
import { HagiborIcon } from 'components/icons/HagiborIcon';
import {
  DASHBOARD_PATH,
  FAQ_PAGE_PATH,
  // HELPDESK_PAGE_PATH,
  HOUSE_PAGE_PATH,
  INSTRUCTIONS_PAGE_PATH,
  // MYDOCUMENTS_PAGE_PATH,
  // TICKET_PAGE_PATH,
} from 'routes/paths';
import { Link } from 'react-router-dom';
import MenuItem from 'components/MenuItem';
import {
  IconArmchair2,
  IconBook,
  IconChevronRight,
  IconHelpHexagon,
  // IconMessage,
  // IconScript,
  // IconTicket,
} from '@tabler/icons-react';
import useLang from 'lang';
import PanoramaIcon from 'components/icons/PanoramaIcon';
import { SabinovskaIcon } from 'components/icons/SabinovskaIcon';
import { PodLesomIcon } from 'components/icons/PodLesomIcon';
import { LivanaIcon } from 'components/icons/LivanaIcon';
import BarboraIcon from 'components/icons/BarboraIcon';

interface SimpleLayoutProps {
  children: React.ReactNode;
}

/**
 * SimpleLayout component with dynamic main content area.
 *
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 */
function SimpleLayout({ children }: SimpleLayoutProps) {
  const [opened, { toggle }] = useDisclosure();
  const { _t } = useLang();

  //Logo podľa inštancie
  let HeaderIconComponent;
  switch (window.location.host) {
    case 'hagiborwa.phillip.city':
      HeaderIconComponent = HagiborIcon;
      break;
    case 'panoramawa.phillip.city':
      HeaderIconComponent = PanoramaIcon;
      break;
    case 'sabinovskawa.phillip.city':
      HeaderIconComponent = SabinovskaIcon;
      break;
    case 'podlesomwa.phillip.city':
      HeaderIconComponent = PodLesomIcon;
      break;
    case 'zagatomwa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'hviezdoslavwa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'livanawa.phillip.city':
      HeaderIconComponent = LivanaIcon;
      break;
    case 'slnecnawa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'barborawa.phillip.city':
      HeaderIconComponent = BarboraIcon;
      break;
    case 'sitkawa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'parkovacidomwa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'mrboxwa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'mertukywa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'alexandrawa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'novenabreziewa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'batoverezidenciewa.phillip.city':
      HeaderIconComponent = HeaderIcon;
      break;
    case 'localhost:3001':
      HeaderIconComponent = HeaderIcon;
      break;
    default:
      HeaderIconComponent = HeaderIcon;
      break;
  }

  return (
    <AppShell
      header={{ height: 78 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
    >
      <AppShell.Header>
        <Flex direction="row" justify="space-between" align="center" py="0.5rem" px="1rem">
          <Box component={Link} h={64} to={DASHBOARD_PATH.original}>
            <HeaderIconComponent />
          </Box>
          <Flex direction="row" align="center" justify="flex-end" w="50%">
            <Box pr="1.5rem">
              <LanguageMenu />
            </Box>
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="md" color={theme.colors?.gray?.[7]} />
          </Flex>
        </Flex>
      </AppShell.Header>

      <AppShell.Navbar py="md" bg={theme.colors?.gray?.[1]}>
        <MenuItem
          to={HOUSE_PAGE_PATH.original}
          icon={IconArmchair2}
          title={_t('My household')}
          description={_t('Overview of your invoices, contracts ...')}
          iconRight={IconChevronRight}
          onClick={toggle}
        />

        {/*MyDocuments*/}
        {/* <MenuItem
          to={MYDOCUMENTS_PAGE_PATH.original}
          icon={IconScript}
          title={_t('My Documents')}
          description={_t('List of your documents')}
          iconRight={IconChevronRight}
          onClick={toggle}
        /> */}
        {/*Instructions */}
        <MenuItem
          to={INSTRUCTIONS_PAGE_PATH.original}
          icon={IconBook}
          title={_t('Instructions')}
          description={_t('Instructions for operation and settings of smart devices')}
          iconRight={IconChevronRight}
          onClick={toggle}
        />

        {/*FAQ */}
        <MenuItem
          to={FAQ_PAGE_PATH.original}
          icon={IconHelpHexagon}
          title={_t('FAQ')}
          description={_t('Frequently asked questions')}
          iconRight={IconChevronRight}
          onClick={toggle}
        />
        {/*Helpdesk */}
        {/* <MenuItem
          to={HELPDESK_PAGE_PATH.original}
          icon={IconMessage}
          title={_t('Ask')}
          description={_t('Online chat for your questions')}
          iconRight={IconChevronRight}
          onClick={toggle}
        /> */}
        {/*Ticket */}
        {/* <MenuItem
          to={TICKET_PAGE_PATH.original}
          icon={IconTicket}
          title={_t('Add a ticket')}
          description={_t('Temporary')}
          iconRight={IconChevronRight}
          onClick={toggle}
        /> */}
      </AppShell.Navbar>

      <AppShell.Main bg={theme.colors?.gray?.[1]}>{children}</AppShell.Main>
    </AppShell>
  );
}
export default SimpleLayout;
