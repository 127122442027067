/**
 * Icon for Barbora.
 */
export default function BarboraIcon() {
  return (
    <svg
      viewBox="144.8678071539658 153.72 515.2255054432349 303.65999999999997"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="62"
    >
      <defs>
        <clipPath id="clip_0">
          <path clip-rule="evenodd" d="M-9-9H801V621H-9Z" transform="matrix(1,0,0,-1,9,621)" />
        </clipPath>
      </defs>
      <g clip-path="url(#clip_0)">
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M260.9861 354.615 306.1168 409.2021 316.0026 401.8949 311.7044 389.4301 346.5193 429.8332 364.572 417.7981 361.9925 408.3423 395.5184 458.2015 414.0006 434.1314 423.0266 430.6929 446.2367 395.0181 445.377 402.7547 464.2889 420.3771 508.9898 362.3522 484.0603 378.2551 468.5871 410.4913 462.5697 389.8602 465.1486 378.6851 458.2715 387.7115 456.5523 395.8775 449.2451 390.2905 462.5697 368.3699 439.3602 389.0007 413.5706 364.9309 427.7543 401.4652 395.9484 447.4556 382.6242 397.167 369.2999 381.6935 364.142 398.0268 329.3269 371.378 344.3706 396.7373 347.3793 408.7718 306.5465 371.808 300.5291 383.8425"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M183.3046 287.176C188.5249 288.7917 188.4008 294.2606 188.4008 294.2606V319.7404C188.2763 325.8306 183.4291 330.6781 177.3388 330.6781H161.1808V243.6738H177.3388C183.4291 243.6738 188.2763 248.5213 188.4008 254.6116V280.0913C188.4008 280.0913 188.5249 285.5602 183.3046 287.176ZM179.4518 296.3735C179.4518 292.3963 177.2144 291.2777 174.8529 291.2777H170.0056V321.7292H174.8529C177.2144 321.7292 179.4518 320.4862 179.4518 317.2547ZM179.4518 257.0973C179.4518 253.7413 177.2144 252.4986 174.8529 252.4986H170.0056V283.0742H174.8529C177.2144 283.0742 179.4518 281.9557 179.4518 278.1026"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M264.9643 243.6738H255.1453L247.3149 303.5826 239.4845 243.6738H229.6655L242.8404 330.6781H247.6879C251.4166 330.6781 252.038 328.3166 252.411 325.9551"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M332.4546 243.6738V276.984C332.4546 276.984 332.7032 283.4473 329.2231 285.1872 332.8277 286.0574 332.4546 292.8934 332.4546 292.8934V319.7404C332.4546 325.8306 327.4829 330.6781 321.5171 330.6781H305.2349V243.6738H314.1839V281.0857H318.907C321.2685 281.0857 323.5056 279.7186 323.5056 275.4924V243.6738ZM314.1839 289.5375V322.3505H318.907C321.2685 322.3505 323.5056 321.1075 323.5056 317.876V294.5092C323.5056 290.5319 321.2685 289.5375 318.907 289.5375"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M402.0579 287.176C407.2782 288.7917 407.154 294.2606 407.154 294.2606V319.7404C407.0296 325.8306 402.1824 330.6781 396.0921 330.6781H379.9341V243.6738H396.0921C402.1824 243.6738 407.0296 248.5213 407.154 254.6116V280.0913C407.154 280.0913 407.2782 285.5602 402.0579 287.176ZM398.2051 296.3735C398.2051 292.3963 395.9677 291.2777 393.6061 291.2777H388.7589V321.7292H393.6061C395.9677 321.7292 398.2051 320.4862 398.2051 317.2547ZM398.2051 257.0973C398.2051 253.7413 395.9677 252.4986 393.6061 252.4986H388.7589V283.0742H393.6061C395.9677 283.0742 398.2051 281.9557 398.2051 278.1026"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M464.9496 331.5481C458.735 331.5481 453.7633 326.5764 453.7633 320.4862V253.7413C453.8877 247.7755 458.8594 242.7831 464.9496 242.7831H471.7857C477.7517 243.0317 482.4748 247.7755 482.599 253.7413V320.4862C482.599 326.4523 477.7517 331.2995 471.7857 331.5481ZM467.4353 250.6343C464.8252 250.7584 462.7123 252.8714 462.7123 255.4815V318.8704C462.7123 321.4806 464.8252 323.5935 467.4353 323.7177H468.8028C471.4129 323.7177 473.5259 321.6047 473.5259 318.8704V255.4815C473.5259 252.7472 471.4129 250.6343 468.8028 250.6343"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M556.1796 243.6738V276.984C556.1796 276.984 556.4282 283.4473 552.9481 285.1872 556.5526 286.0574 556.1796 292.8934 556.1796 292.8934V319.7404C556.1796 325.8306 551.2079 330.6781 545.2421 330.6781H528.9599V243.6738H537.9089V281.0857H542.632C544.9935 281.0857 547.2306 279.7186 547.2306 275.4924V243.6738ZM537.9089 289.5375V322.3505H542.632C544.9935 322.3505 547.2306 321.1075 547.2306 317.876V294.5092C547.2306 290.5319 544.9935 289.5375 542.632 289.5375"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#231f20"
          d="M632.8675 243.6738H623.0486L615.2182 303.5826 607.3877 243.6738H597.5688L610.7437 330.6781H615.5912C619.3199 330.6781 619.9413 328.3166 620.3143 325.9551"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M260.7279 193.0572C262.6075 193.6389 262.5627 195.6081 262.5627 195.6081V204.7822C262.518 206.9751 260.7727 208.7206 258.5798 208.7206H252.762V177.3938H258.5798C260.7727 177.3938 262.518 179.1391 262.5627 181.332V190.5063C262.5627 190.5063 262.6075 192.4756 260.7279 193.0572ZM259.3406 196.3689C259.3406 194.9369 258.535 194.5341 257.6849 194.5341H255.9396V205.4982H257.6849C258.535 205.4982 259.3406 205.0509 259.3406 203.8873ZM259.3406 182.2272C259.3406 181.0188 258.535 180.5715 257.6849 180.5715H255.9396V191.5804H257.6849C258.535 191.5804 259.3406 191.1776 259.3406 189.7903"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M288.8773 208.7206H286.5053C286.416 208.7206 285.6104 208.6758 285.476 207.6463L283.4175 195.787 281.359 207.6463C281.2246 208.6758 280.419 208.7206 280.3294 208.7206H277.9577L281.8063 190.4168V178.3338C281.8511 177.7966 282.2539 177.3938 282.7463 177.3938H285.0284V190.4168"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M315.4601 208.7206H302.6611C302.1239 208.7206 301.6766 208.273 301.6766 207.7359V205.543H306.1964V178.0651C306.1964 177.8414 306.3308 177.3938 307.0916 177.3938H309.3738V204.7822C309.4186 205.185 309.7318 205.543 310.1794 205.543H312.6855C313.7148 205.543 314.2071 205.9906 314.386 206.3934"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M332.6003 209.0339C330.3626 209.0339 328.5726 207.2438 328.5726 205.0509V181.0188C328.6174 178.8707 330.4074 177.0732 332.6003 177.0732H335.0616C337.2097 177.1628 338.9105 178.8707 338.955 181.0188V205.0509C338.955 207.199 337.2097 208.9443 335.0616 209.0339ZM333.4955 179.8999C332.5555 179.9447 331.7947 180.7055 331.7947 181.6455V204.4689C331.7947 205.4089 332.5555 206.1697 333.4955 206.2142H333.9876C334.9276 206.2142 335.6881 205.4537 335.6881 204.4689V181.6455C335.6881 180.6608 334.9276 179.8999 333.9876 179.8999"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M366.6569 208.7206H363.4347L360.2574 187.8659 357.2589 207.5122C357.08 208.5415 356.5429 208.7206 356.3192 208.7206H353.8579L358.3329 179.5867C358.3777 179.3183 358.6462 177.3938 360.2574 177.3938H360.481 361.8683"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M386.3928 210.1079 388.1381 213.8669H390.5546C390.5546 213.8669 391.0022 213.9117 390.8679 213.5537L389.9282 211.1372C389.5254 210.0631 388.4961 210.1079 388.3618 210.1079ZM386.348 180.5715V191.446C386.348 191.8488 386.706 192.2068 387.1089 192.2068H388.4513C389.4806 192.2068 389.9282 192.6544 390.1518 193.0572 390.2862 193.3257 390.8234 194.6237 391.1814 195.429H386.348V204.7377C386.348 205.185 386.6613 205.4982 387.1089 205.4982H389.7045C390.7338 205.4982 391.2259 205.9906 391.405 206.3934L392.4791 208.7206H384.1104C383.5735 208.7206 383.1707 208.3178 383.1259 207.8254V177.3938H390.2862C391.3155 177.3938 391.8078 177.8414 392.0315 178.2442 392.1659 178.5127 392.7027 179.7659 393.0608 180.5715"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M435.352 208.7206V177.3938H441.5726C443.7655 177.3938 445.5108 179.1391 445.5556 181.332V204.7822C445.5108 206.9751 443.7655 208.7206 441.5726 208.7206ZM442.3779 182.2272C442.3779 181.0188 441.5726 180.5715 440.6774 180.5715H438.5741V205.4982H440.6774C441.5726 205.4982 442.3779 205.0509 442.3779 203.8873"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M466.1416 209.0339C463.9039 209.0339 462.1139 207.2438 462.1139 205.0509V181.0188C462.1586 178.8707 463.9487 177.0732 466.1416 177.0732H468.6029C470.751 177.1628 472.4518 178.8707 472.4963 181.0188V205.0509C472.4963 207.199 470.751 208.9443 468.6029 209.0339ZM467.0368 179.8999C466.0968 179.9447 465.336 180.7055 465.336 181.6455V204.4689C465.336 205.4089 466.0968 206.1697 467.0368 206.2142H467.5289C468.4689 206.2142 469.2294 205.4537 469.2294 204.4689V181.6455C469.2294 180.6608 468.4689 179.8999 467.5289 179.8999"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M501.6302 208.7206H499.6613C498.4977 208.7206 498.2737 207.7359 498.2292 207.6018L494.6936 189.5666 490.9793 207.557C490.9793 207.557 490.8002 208.7206 489.5473 208.7206H487.578V177.3938H490.7554V193.5496L492.6351 185.3597C492.8142 184.6885 493.1722 182.8089 495.0516 182.8089H496.0363L498.4529 193.5496V177.3938H501.6302"
          transform="matrix(1,0,0,-1,9,621)"
        />
        <path
          fill-rule="evenodd"
          fill="#2d6295"
          d="M526.244 208.7206H523.872C523.7827 208.7206 522.9771 208.6758 522.8427 207.6463L520.7842 195.787 518.7257 207.6463C518.5913 208.6758 517.7857 208.7206 517.6962 208.7206H515.3244L519.173 190.4168V178.3338C519.2178 177.7966 519.6206 177.3938 520.113 177.3938H522.3951V190.4168"
          transform="matrix(1,0,0,-1,9,621)"
        />
      </g>
    </svg>
  );
}
