/**
 * Icon for Livana.
 */
export function LivanaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 699.03735 159.66667"
      width="172"
      height="62"
      id="svg2"
      version="1.1"
    >
      <defs id="defs6">
        <clipPath id="clipPath28" clipPathUnits="userSpaceOnUse">
          <path id="path26" d="M 0,119.75 H 524.278 V 0 H 0 Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,159.66667)" id="g10">
        <g transform="translate(188.6957,37.9274)" id="g12">
          <path
            id="path14"
            style={{ fill: '#170708', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d="M 0,0 H 38.256 V -13.437 H -14.666 V 58.357 H 0 Z"
          />
        </g>
        <path
          id="path16"
          style={{ fill: '#170708', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          d="m 240.285,75.157 h 13.949 V 24.491 h -13.949 z m -0.411,19.077 H 254.54 V 82.541 h -14.666 z"
        />
        <g transform="translate(282.9496,75.1568)" id="g18">
          <path
            id="path20"
            style={{ fill: '#170708', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d="M 0,0 13.744,-36.922 27.487,0 H 43.281 L 21.641,-50.666 H 5.744 L -15.794,0 Z"
          />
        </g>
        <g id="g22">
          <g clip-path="url(#clipPath28)" id="g24">
            <g transform="translate(346.1292,38.6451)" id="g30">
              <path
                id="path32"
                style={{ fill: '#170708', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,-3.692 3.247,-5.538 9.744,-5.538 4.238,0 7.913,1.538 11.025,4.614 3.11,3.077 4.7,6.667 4.769,10.77 L 19.897,8.82 C 16.205,8.136 13.418,7.589 11.539,7.179 9.657,6.769 7.675,6.221 5.59,5.538 3.503,4.854 2.051,4.066 1.231,3.179 0.41,2.29 0,1.23 0,0 m -13.846,-1.334 c 0,3.555 0.939,6.342 2.821,8.359 1.879,2.016 5.076,3.708 9.589,5.077 4.513,1.367 11.418,2.734 20.718,4.102 l 6.256,0.923 -0.205,2.052 c -0.206,2.734 -1.23,4.666 -3.077,5.795 -1.846,1.128 -4.753,1.692 -8.718,1.692 -4.171,0 -7.266,-0.736 -9.282,-2.205 C 2.239,22.989 1.128,20.682 0.923,17.537 l -11.59,2.872 c 0.342,5.47 2.821,9.693 7.436,12.666 4.616,2.975 10.273,4.462 16.974,4.462 7.59,0 13.693,-1.641 18.308,-4.923 4.615,-3.282 6.923,-8 6.923,-14.153 v -32.615 h -12 L 25.333,-2.872 H 25.127 C 21.982,-11.077 15.349,-15.18 5.231,-15.18 c -5.813,0 -10.446,1.247 -13.898,3.744 -3.453,2.496 -5.179,5.862 -5.179,10.102"
              />
            </g>
            <g transform="translate(402.9466,75.1568)" id="g34">
              <path
                id="path36"
                style={{ fill: '#170708', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 h 11.897 l 2.051,-12.205 h 0.206 c 3.487,8.82 9.913,13.23 19.282,13.23 13.81,0 20.717,-7.35 20.717,-22.05 V -50.666 H 40.102 v 25.949 c 0,4.718 -0.94,8.067 -2.821,10.051 -1.881,1.982 -4.906,2.974 -9.076,2.974 -9.504,0 -14.257,-4.958 -14.257,-14.871 V -50.666 H 0 Z"
              />
            </g>
            <g transform="translate(485.304,38.6451)" id="g38">
              <path
                id="path40"
                style={{ fill: '#170708', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,-3.692 3.247,-5.538 9.744,-5.538 4.239,0 7.913,1.538 11.026,4.614 3.11,3.077 4.699,6.667 4.769,10.77 L 19.897,8.82 C 16.205,8.136 13.418,7.589 11.539,7.179 9.657,6.769 7.675,6.221 5.59,5.538 3.503,4.854 2.052,4.066 1.231,3.179 0.411,2.29 0,1.23 0,0 m -13.846,-1.334 c 0,3.555 0.94,6.342 2.821,8.359 1.88,2.016 5.076,3.708 9.589,5.077 4.513,1.367 11.418,2.734 20.718,4.102 l 6.257,0.923 -0.206,2.052 c -0.206,2.734 -1.23,4.666 -3.077,5.795 -1.846,1.128 -4.753,1.692 -8.717,1.692 -4.172,0 -7.267,-0.736 -9.283,-2.205 C 2.239,22.989 1.128,20.682 0.923,17.537 l -11.59,2.872 c 0.342,5.47 2.821,9.693 7.437,12.666 4.615,2.975 10.272,4.462 16.974,4.462 7.589,0 13.692,-1.641 18.307,-4.923 4.616,-3.282 6.923,-8 6.923,-14.153 V -14.154 H 26.975 L 25.333,-2.872 H 25.127 C 21.982,-11.077 15.35,-15.18 5.231,-15.18 c -5.813,0 -10.445,1.247 -13.897,3.744 -3.454,2.496 -5.18,5.862 -5.18,10.102"
              />
            </g>
            <g transform="translate(41.2171,46.4889)" id="g42">
              <path
                id="path44"
                style={{ fill: '#fc673e', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="M 0,0 52.456,52.455 78.138,26.772 25.683,-25.684 Z M 3.879,44.701 13.882,34.698 -10.409,10.408 -20.412,20.411 Z M 52.456,73.261 24.291,45.096 3.879,65.507 -41.217,20.411 -20.806,0 25.683,-46.489 98.944,26.772 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
