/**
 * Icon for Sabinovska.
 */
export function SabinovskaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 546.66669"
      id="svg2"
      version="1.1"
      width="172"
      height="62"
    >
      <defs id="defs6">
        <clipPath id="clipPath26" clipPathUnits="userSpaceOnUse">
          <path id="path24" d="M 0,410 H 1440 V 0 H 0 Z" />
        </clipPath>
        <clipPath id="clipPath54" clipPathUnits="userSpaceOnUse">
          <path id="path52" d="M 0,410 H 1440 V 0 H 0 Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,546.66667)" id="g10">
        <g transform="translate(0,410.0004)" id="g12">
          <path
            id="path14"
            style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d="m 0,0 v -410 h 277.333 v 19.999 H 20 v 370 h 572.742 l 52.591,-29.217 V -144 h 20 V -37.45 L 597.924,0 Z"
          />
        </g>
        <g transform="translate(645.3333,272.6667)" id="g16">
          <path
            id="path18"
            style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d="m 0,0 v -20 h 775.904 v -232.667 h -55.667 v -20 h 74.431 V 0 Z"
          />
        </g>
        <g id="g20">
          <g clip-path="url(#clipPath26)" id="g22">
            <g transform="translate(143.6665,314.6665)" id="g28">
              <path
                id="path30"
                style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,0 -22.356,-16.062 -24.25,-36.979 -1.833,-20.25 5.767,-34.087 9.353,-38.817 3.916,-5.167 16.439,-14.954 16.439,-14.954 0,0 15.817,9.743 21.556,22.504 8.295,18.447 5.817,30.449 2.902,40.746 -1.696,5.993 -6.333,12.833 -11,17.833 -4.667,5 -6.609,6.891 -8.625,8.334 C 4.43,0.06 2.902,2.005 0,0"
              />
            </g>
            <g transform="translate(152.7583,10.4336)" id="g32">
              <path
                id="path34"
                style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 0.424,118.113 c 0,0 0.604,6.541 3.244,12.251 2.818,6.093 9.735,15.01 15.61,19.135 5.875,4.125 11.68,7.876 20.68,10.751 9,2.875 15.25,3.625 20.75,3.25 5.5,-0.375 11.379,-1.711 12.313,-3.375 1.137,-2.027 2.897,-7.267 2.971,-15.267 0.088,-9.515 -2.141,-18.127 -7.537,-24.557 C 62.09,112.715 58.527,110.308 51.333,106 44.619,101.979 34.958,99.25 29.833,98.5 24.708,97.75 22.958,98 22.958,98 L 22.927,0 Z"
              />
            </g>
            <g transform="translate(215.23,245.9277)" id="g36">
              <path
                id="path38"
                style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,0 -28.269,3.875 -44.616,-10.5 -15.827,-13.917 -19.986,-29.743 -20.665,-35.856 -0.742,-6.68 1.689,-22.973 1.689,-22.973 0,0 18.844,-3.961 32.263,1.538 19.399,7.951 26.099,18.742 31.255,28.564 3.001,5.716 4.395,14.168 4.454,21.256 0.059,7.089 -0.052,9.896 -0.538,12.42 C 3.373,-3.116 3.613,-0.563 0,0"
              />
            </g>
            <g transform="translate(76.8277,245.9277)" id="g40">
              <path
                id="path42"
                style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,0 28.269,3.875 44.616,-10.5 15.827,-13.917 19.986,-29.743 20.665,-35.856 0.742,-6.68 -1.689,-22.973 -1.689,-22.973 0,0 -18.844,-3.961 -32.263,1.538 -19.399,7.951 -26.099,18.742 -31.255,28.564 -3.001,5.716 -4.395,14.168 -4.454,21.256 -0.059,7.089 0.052,9.896 0.538,12.42 C -3.373,-3.116 -3.613,-0.563 0,0"
              />
            </g>
            <g transform="translate(140.2525,10)" id="g44">
              <path
                id="path46"
                style={{ fill: '#58a3bd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 -0.424,118.113 c 0,0 -0.604,6.541 -3.244,12.251 -2.818,6.093 -9.735,15.01 -15.61,19.135 -5.875,4.125 -11.68,7.876 -20.68,10.751 -9,2.875 -15.25,3.625 -20.75,3.25 -5.5,-0.375 -11.379,-1.711 -12.313,-3.375 -1.137,-2.027 -2.897,-7.267 -2.971,-15.267 -0.088,-9.515 2.141,-18.127 7.537,-24.557 6.365,-7.586 9.928,-9.993 17.122,-14.301 6.714,-4.021 16.375,-6.75 21.5,-7.5 5.125,-0.75 6.875,-0.5 6.875,-0.5 l 0.031,-98 z"
              />
            </g>
          </g>
        </g>
        <g id="g48">
          <g clip-path="url(#clipPath54)" id="g50">
            <g transform="translate(277.9492,259.062)" id="g56">
              <path
                id="path58"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0.654,-0.146 1.598,-0.291 2.833,-0.291 5.737,0 9.514,3.414 9.514,9.733 0,6.755 -4.648,9.951 -9.878,10.023 H 0 Z m 0,23.75 h 2.542 c 4.576,0 8.498,3.487 8.498,8.644 0,4.503 -2.106,7.989 -8.062,7.989 C 1.67,40.383 0.654,40.238 0,40.02 Z m -5.52,20.047 c 2.324,0.581 5.012,0.944 8.135,0.944 4.43,0 8.207,-0.944 10.822,-3.413 2.106,-1.962 3.195,-4.867 3.195,-8.353 0,-5.012 -2.759,-8.934 -7.553,-10.822 v -0.218 c 5.665,-1.525 9.079,-6.102 9.079,-12.493 0,-4.067 -1.307,-7.336 -3.487,-9.733 -2.759,-3.123 -7.336,-4.43 -12.928,-4.43 -3.559,0 -5.738,0.218 -7.263,0.508 z"
              />
            </g>
            <g transform="translate(309.106,254.6313)" id="g60">
              <path
                id="path62"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 v 20.7 l -10.822,28.254 h 5.956 l 4.358,-12.275 c 1.162,-3.414 2.542,-7.263 3.632,-11.04 h 0.145 c 0.944,3.777 2.252,7.481 3.559,11.258 l 4.14,12.057 h 5.81 L 5.593,20.918 V 0 Z"
              />
            </g>
            <g transform="translate(337.6484,298.5737)" id="g64">
              <path
                id="path66"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 h -8.934 v 5.011 h 23.46 V 0 H 5.593 V -43.942 H 0 Z"
              />
            </g>
            <g transform="translate(359.0732,279.1084)" id="g68">
              <path
                id="path70"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,-10.096 1.961,-20.119 8.208,-20.119 6.246,0 7.917,10.677 7.917,20.264 0,9.152 -1.671,19.974 -7.99,19.974 C 1.961,20.119 0,9.95 0,0 m 21.935,0.363 c 0,-18.376 -6.247,-25.421 -14.164,-25.421 -8.134,0 -13.582,8.643 -13.582,25.13 0,16.198 5.884,24.986 14.382,24.986 8.352,0 13.364,-9.079 13.364,-24.695"
              />
            </g>
            <g transform="translate(395.0259,254.6313)" id="g72">
              <path
                id="path74"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 -11.186,48.954 h 6.029 L -0.436,26.002 C 0.726,20.482 2.179,13.437 3.05,7.19 h 0.218 c 0.727,6.319 2.107,13.147 3.269,18.957 l 4.648,22.807 h 5.956 L 5.738,0 Z"
              />
            </g>
            <g transform="translate(438.167,314.0439)" id="g76">
              <path
                id="path78"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 -9.006,-7.844 h -4.431 L -6.609,0 Z m -13.437,-59.413 v 20.701 l -10.822,28.253 h 5.956 l 4.358,-12.274 c 1.162,-3.414 2.542,-7.264 3.632,-11.04 h 0.145 c 0.944,3.776 2.251,7.481 3.559,11.257 l 4.14,12.057 h 5.81 L -7.844,-38.495 v -20.918 z"
              />
            </g>
            <g transform="translate(463.2231,259.1348)" id="g80">
              <path
                id="path82"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0.799,-0.146 1.598,-0.146 2.106,-0.146 6.174,0 12.13,4.503 12.13,21.136 0,12.348 -3.777,19.103 -11.621,19.103 C 1.743,40.093 0.726,40.02 0,39.802 Z m -5.52,43.724 c 2.106,0.654 4.721,0.944 7.481,0.944 5.157,0 8.789,-1.307 11.476,-3.704 3.777,-3.268 6.609,-9.587 6.609,-19.828 0,-11.186 -2.76,-17.287 -5.883,-20.7 -3.196,-3.56 -7.844,-5.23 -13.51,-5.23 -2.251,0 -4.575,0.146 -6.173,0.508 z"
              />
            </g>
            <g transform="translate(493.7266,279.1084)" id="g84">
              <path
                id="path86"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,-10.096 1.961,-20.119 8.208,-20.119 6.246,0 7.917,10.677 7.917,20.264 0,9.152 -1.671,19.974 -7.99,19.974 C 1.961,20.119 0,9.95 0,0 m 21.935,0.363 c 0,-18.376 -6.247,-25.421 -14.164,-25.421 -8.134,0 -13.582,8.643 -13.582,25.13 0,16.198 5.884,24.986 14.382,24.986 8.352,0 13.364,-9.079 13.364,-24.695"
              />
            </g>
            <g transform="translate(551.9761,275.6943)" id="g88">
              <path
                id="path90"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="M 0,0 C -0.363,6.464 -0.727,13.655 -0.508,19.175 H -0.799 C -1.889,13.728 -3.196,7.917 -4.721,2.179 l -5.956,-22.951 h -3.632 l -5.665,22.007 c -1.671,6.246 -3.05,12.275 -4.067,17.94 h -0.218 C -24.187,13.437 -24.55,6.61 -24.84,-0.363 l -0.944,-20.7 h -5.012 l 2.905,48.954 h 6.101 l 5.666,-22.37 c 1.67,-6.465 2.905,-11.259 3.922,-16.851 h 0.218 c 0.944,5.592 2.033,10.241 3.631,16.851 l 5.52,22.37 H 3.414 L 6.246,-21.063 H 1.017 Z"
              />
            </g>
            <g transform="translate(316.6899,26.9463)" id="g92">
              <path
                id="path94"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="M 0,0 C 6.547,-4.192 18.614,-7.352 28.245,-7.075 48.966,-6.479 60.837,7.3 60.267,27.144 59.747,45.24 48.921,56.028 33.118,67.257 17.047,77.603 -4.787,95.377 -5.517,120.768 c -0.856,29.767 19.236,52.253 50.464,53.15 12.258,0.353 22.874,-3.14 27.644,-6.507 l -4.699,-19.413 c -5.062,3.359 -13.326,6.335 -23.541,6.041 C 24.796,153.477 16.169,138.623 16.555,125.199 17.117,105.645 27.55,98.352 46.614,85.463 70.733,69.507 81.759,51.715 82.389,29.825 83.362,-4.029 60.335,-26.014 28.815,-26.92 15.39,-27.306 1.256,-23.331 -4.407,-19.404 Z"
              />
            </g>
            <g transform="translate(481.4546,67.7354)" id="g96">
              <path
                id="path98"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 -8.612,42.076 c -2.461,11.812 -4.676,25.591 -6.644,37.895 h -0.738 c -1.969,-12.55 -4.429,-26.822 -6.89,-37.895 L -31.496,0 Z M -34.203,-18.03 -45.03,-67.735 H -63.484 L -25.591,98.111 H -4.675 L 32.234,-67.735 H 13.533 L 2.707,-18.03 Z"
              />
            </g>
            <g transform="translate(556.9878,15.9941)" id="g100">
              <path
                id="path102"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 2.215,-0.492 5.414,-0.984 9.597,-0.984 19.439,0 32.234,11.565 32.234,32.972 0,22.884 -15.748,33.711 -33.465,33.957 H 0 Z m 0,80.463 h 8.612 c 15.502,0 28.79,11.81 28.79,29.281 0,15.256 -7.136,27.067 -27.313,27.067 -4.429,0 -7.874,-0.492 -10.089,-1.23 z m -18.701,67.913 c 7.874,1.969 16.979,3.199 27.559,3.199 15.01,0 27.806,-3.199 36.664,-11.565 7.136,-6.644 10.827,-16.487 10.827,-28.297 0,-16.979 -9.351,-30.266 -25.591,-36.663 v -0.738 c 19.193,-5.168 30.758,-20.67 30.758,-42.324 0,-13.779 -4.429,-24.852 -11.811,-32.972 -9.351,-10.58 -24.852,-15.01 -43.799,-15.01 -12.057,0 -19.439,0.738 -24.607,1.723 z"
              />
            </g>
            <path
              id="path104"
              style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
              d="M 663.927,0 H 644.98 v 165.847 h 18.947 z"
            />
            <g transform="translate(696.7671)" id="g106">
              <path
                id="path108"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="M 0,0 V 165.847 H 17.717 L 48.229,88.091 C 55.118,70.128 63.73,48.229 69.39,30.266 h 0.492 c -1.477,26.083 -2.215,47.49 -2.215,76.279 v 59.302 H 84.646 V 0 H 66.93 L 35.434,79.725 C 28.543,96.457 21.407,115.403 15.502,136.072 H 15.01 c 1.476,-22.144 1.969,-43.306 1.969,-78.739 V 0 Z"
              />
            </g>
            <g transform="translate(829.2866,84.8916)" id="g110">
              <path
                id="path112"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 0,-34.202 6.645,-68.159 27.806,-68.159 21.161,0 26.821,36.172 26.821,68.651 0,31.004 -5.66,67.668 -27.067,67.668 C 6.645,68.16 0,33.711 0,0 m 74.312,1.23 c 0,-62.253 -21.162,-86.122 -47.983,-86.122 -27.558,0 -46.014,29.283 -46.014,85.138 0,54.872 19.931,84.646 48.721,84.646 28.297,0 45.276,-30.758 45.276,-83.662"
              />
            </g>
            <g transform="translate(956.1343)" id="g114">
              <path
                id="path116"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 -37.894,165.847 h 20.423 L -1.477,88.091 C 2.46,69.39 7.382,45.521 10.334,24.36 h 0.738 c 2.461,21.408 7.136,44.537 11.074,64.223 l 15.748,77.264 H 58.07 L 19.438,0 Z"
              />
            </g>
            <g transform="translate(1033.2593,23.376)" id="g118">
              <path
                id="path120"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 c 5.413,-3.69 15.502,-6.644 23.622,-6.644 17.47,0 27.805,11.32 27.805,28.051 0,15.257 -8.859,24.607 -21.9,34.449 -13.287,9.105 -31.25,24.607 -31.25,46.014 0,25.099 17.471,43.554 43.799,43.554 10.335,0 19.194,-3.199 23.13,-6.152 l -4.429,-16.24 c -4.183,2.952 -11.073,5.659 -19.685,5.659 -16.487,0 -24.114,-12.303 -24.114,-23.622 0,-16.486 8.612,-22.883 24.36,-34.203 19.932,-14.025 28.79,-29.281 28.79,-47.736 0,-28.543 -19.932,-46.506 -46.506,-46.506 -11.319,0 -23.13,3.691 -27.806,7.136 z"
              />
            </g>
            <g transform="translate(1129.606,165.8467)" id="g122">
              <path
                id="path124"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="m 0,0 h 18.701 v -78.74 h 0.492 c 3.937,6.889 7.136,13.533 10.334,19.93 L 60.531,0 h 20.916 l -40.6,-70.866 41.585,-94.981 H 62.008 l -33.956,79.971 -9.351,-15.994 v -63.977 H 0 Z"
              />
            </g>
            <g transform="translate(1308.2886,201.2793)" id="g126">
              <path
                id="path128"
                style={{ fill: '#4f4f4f', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d="M 0,0 -30.512,-26.574 H -45.521 L -22.392,0 Z m -18.209,-136.318 -8.612,42.076 c -2.46,11.811 -4.675,25.591 -6.644,37.894 h -0.738 c -1.968,-12.549 -4.429,-26.821 -6.89,-37.894 l -8.612,-42.076 z m -34.202,-15.256 -10.827,-49.705 h -18.455 l 37.894,165.846 h 20.915 l 36.909,-165.846 h -18.7 l -10.827,49.705 z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
